<template>
  <div>
    <div v-if="initialized || $route.path === '/oauth/login'">
      <div v-if="authUser" class="container">
        <div class="bg-white md:flex shadow xl:my-12 xl:rounded min-h-screen xl:min-h-0">
          <div class="w-full md:w-1/4 bg-black text-white flex flex-col xl:rounded-l">
            <div class="w-full flex items-center justify-between smdm:justify-center p-6">
              <img
                src="/logo_black.png"
                alt="Fine &amp; Country"
                class="w-32 sm:w-64 inline-block"
              />
              <div class="md:hidden">
                <i class="fas fa-bars cursor-pointer" @click="menuOpen = !menuOpen"></i>
              </div>
            </div>
            <div class="w-full flex-grow flex-col justify-between" :class="menuOpen ? 'flex' : 'hidden md:flex'">
              <div class="flex flex-col">
                <router-link to="/" class="px-6 py-3 hover:bg-gray-900">
                  Job List
                </router-link>
                <router-link to="/availability" class="px-6 py-3 hover:bg-gray-900">
                  Availability
                </router-link>
                <a :href="ecoSystemUrl" class="px-6 py-3 hover:bg-gray-900">
                  Nurtur Ecosystem
                </a>
              </div>

              <router-link to="/account" class="px-6 py-3 flex items-center bg-gray-700 hover:bg-gray-600 xl:rounded-bl">
                <img v-if="authUser.small_photo_url" :src="authUser.small_photo_url" class="w-12 h-12 mr-3 rounded-full bg-gray-800 object-cover" />
                <div v-else class="w-12 h-12 rounded-full mr-3 bg-gray-800 text-white flex items-center justify-center">
                  <i class="far fa-user"></i>
                </div>
                {{ authUser.first_name }} {{ authUser.surname }}
              </router-link>
            </div>
          </div>
          <div class="w-full md:w-3/4">
            <status-message />
            <div class="p-6">
              <error-page v-if="errorCode" />
              <router-view v-else />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <router-view name="auth" />
      </div>
    </div>
    <div v-else class="h-screen flex items-center justify-center flex-col">
      <img
        src="/logo_white.png"
        alt="Fine &amp; Country"
        class="w-64 inline-block"
      />

      <loader class="mt-12" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Loader from "../components/Loader";
import StatusMessage from "../components/StatusMessageComponent";
import ErrorPage from '../pages/ErrorPage.vue';

export default {
  components: {
    Loader,
    StatusMessage,
    ErrorPage
  },
  data() {
    return {
      menuOpen: false
    }
  },
  computed: {
    ...mapState(['initialized', 'authUser', 'errorCode']),
    ecoSystemUrl () {
      return process.env.VUE_APP_ECOSYSTEM_URL;
    }
  },
  created: function () {
    if (this.$route.path === '/oauth/login') {
      return
    }

    this.fetchAuthUser().then(
      () => {
        this.setInitialized();
      },
      () => {
        this.setInitialized();
      }
    );
  },
  methods: {
    ...mapActions(["fetchAuthUser"]),
    ...mapMutations(["setInitialized"]),
  },
  watch: {
    $route() {
      this.menuOpen = false
    }
  }
};
</script>